<template>
  <div>
    <div class="header">
      <h1 class="overlay">INQUIRY</h1>
      <div class="headerimage" />
    </div>

    <div class="container contentDesc">
      <div class="title1">
        <h2>
          <strong>마케팅 제휴 문의</strong>
        </h2>
      </div>
    </div>

    <div class="container">
      <section class="section-mem-agree mt-60">
        <h4 align="center">개인정보 수집 및 활용 동의서</h4>
        <p align="center">
          SK핀크스(주)는 홈페이지를 통하여 적법하고 공정한 수단에 의하여 고객님의 개인정보를 수집합니다.
        </p>

        <table class="table table-borderless">
          <thead align="center">
            <tr style="border-top: 2px solid; border-bottom: 1px solid #cfcfcf; background-color: #f3f3f3">
              <th style="width: 40%; border-right: 1px solid #cfcfcf">필수항목</th>
              <th style="width: 40%; border-right: 1px solid #cfcfcf">수집 및 이용목적</th>
              <th style="width: 20%">보유기간</th>
            </tr>
          </thead>
          <tbody align="center">
            <tr style="border-bottom: 1px solid">
              <td style="border-right: 1px solid #cfcfcf">
                <div>성명, 이메일, 연락처</div>
              </td>
              <td style="border-right: 1px solid #cfcfcf">
                <div>- 예약 및 상담, 문의사항 전달</div>
              </td>
              <td>
                <div>목적 달성 후 파기</div>
              </td>
            </tr>
          </tbody>
        </table>

        <p>필수 항목에 대한 수집 및 이용에 동의 하지 않을 권리는 있으나, 미동의 시 서비스를 이용하실 수 없습니다.</p>

        <hr class="mt-20 mb-10" />

        <div align="right">
          <label class="check-style">
            <input type="checkbox" value="" class="ck" v-model="agree1" />
            <span> 위 필수 항목에 대한 개인정보 수집 및 이용에 대해 동의합니다.</span>
          </label>
        </div>
      </section>

      <section class="mt-5">
        <h4 align="center">개인정보의 취급위탁</h4>
        <p align="center">
          SK핀크스(주)는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나<br />
          원활한 업무 처리를 위해 필요한 경우 업무의 일부 또는 전부를 선정한 다음 다음 아래의 위탁업체에 위탁할 수
          있습니다.
        </p>

        <table class="table table-borderless">
          <thead align="center">
            <tr style="border-top: 2px solid; border-bottom: 1px solid #cfcfcf; background-color: #f3f3f3">
              <th style="width: 30%; border-right: 1px solid #cfcfcf">수탁자명</th>
              <th style="width: 70%">취급 위탁 업무</th>
            </tr>
          </thead>
          <tbody align="center">
            <tr style="border-bottom: 1px solid #cfcfcf">
              <td style="border-right: 1px solid #cfcfcf; background-color: #f3f3f3">
                <div>SK 네트웍스서비스㈜</div>
              </td>
              <td>
                <div>서버 네트워킹 서비스, 홈페이지 운영 관리 및 논리적 및 물리적 데이터 베이스 관리</div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid #cfcfcf">
              <td style="background-color: #f3f3f3; border-right: 1px solid #cfcfcf">
                <div>비즈톡 주식회사</div>
              </td>
              <td>
                <div>문자발송(SMS) 서비스</div>
              </td>
            </tr>
            <tr style="border-bottom: 1px solid">
              <td style="border-right: 1px solid #cfcfcf; background-color: #f3f3f3">
                <div>SCI 평가정보㈜</div>
              </td>
              <td>
                <div>아이핀/휴대폰을 통한 본인인증</div>
              </td>
            </tr>
          </tbody>
        </table>

        <p>취급위탁에 동의하지 않을 권리는 있으나, 미동의 시 서비스를 이용하실 수 없습니다.</p>

        <hr class="mt-20 mb-10" />

        <div align="right">
          <label class="check-style">
            <input type="checkbox" value="" class="ck" v-model="agree2" />
            <span> 개인정보의 처리위탁에 동의합니다.</span>
          </label>
        </div>
      </section>
      <hr class="mt-3 mb-3" />
      <p class="font-size-15">
        "SK핀크스(주)"는 "개인정보보호법" 및 "정보통신망 이용 촉진 및 정보보호 등에 관한 법률"을 준수하며 상기 명시된
        이외의 개인정보보호 관련 사항은 "SK핀크스"의 개인정보 처리방침에 준하며 자세한 내용은
        <a href="http://www.thepinx.co.kr" target="_blank">www.thepinx.co.kr</a>에서 확인하실 수 있습니다.
      </p>

      <section class="section-agree-all grid-container">
        <div class="col-left">개인정보 수집, 이용, 제공 동의서에 모두 동의합니다.</div>
        <div class="col-right">
          <label class="check-style">
            <input type="checkbox" value="" class="allCheck" v-model="agree3" />
            <span>모두 동의</span>
          </label>
        </div>
      </section>

      <div class="mt-5 mb-5" align="center">
        <a href="#" @click="nextPage()" class="button b-black b-xlarge" style="width: 300px">다음</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Marketingaffiliate',
  data() {
    return {
      agree1: false,
      agree2: false,
      agree3: false,
    };
  },
  watch: {
    agree3(newVal, oldVal) {
      this.agree1 = newVal;
      this.agree2 = newVal;
    },
  },
  methods: {
    nextPage() {
      if (!this.agree1) {
        alert('개인정보 수집 및 이용에 대한 동의가 필요합니다.');
        return;
      }
      if (!this.agree2) {
        alert('개인정보의 처리위탁에 대한 동의가 필요합니다.');
        return;
      }
      if (this.agree1 && this.agree2) {
        this.$emit('nextPage');
      }
    },
  },
};
</script>

<style lang="scss">
.section-mice {
  margin-bottom: 70px;
  padding: 45px;
  background-color: #f2f2f3;
  text-align: center;
}
.button.b-small {
  padding-top: 0;
  padding-bottom: 0;
  height: 33px;
  font-size: 15px;
  line-height: 33px;
}
.button.b-black {
  background-color: #333333;
  color: #fff;
}
.button {
  position: relative;
  display: inline-block;
  padding: 14px 22px;
  height: 50px;
  box-sizing: border-box;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.check-style {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
}
.check-style span {
  position: relative;
  display: inline-block;
  cursor: default;
  line-height: 17px;
  vertical-align: middle;
}
.check-style input:checked + span::before {
  background: #fff url('~@/assets/images/common/icon_check_black.png') center center no-repeat;
  background-size: 80% 70%;
}
.check-style span::before {
  content: ' ';
  display: inline-block;
  margin-right: 6px;
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  vertical-align: middle;
}
.section-mem-agree .check-style {
  margin-top: 10px;
}
.section-agree-all {
  margin-top: 30px;
  padding: 40px;
  background-color: #f1f1f1;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  color: #333333;
  font-size: 20px;
}
.grid-container::before,
.grid-container::after {
  content: '';
  display: table;
}
.grid-container > .col-left,
.grid-container > .row > .col-left {
  float: left;
  box-sizing: border-box;
}
.grid-container > .col-right,
.grid-container > .row > .col-right {
  float: right;
  box-sizing: border-box;
}
.grid-container {
  zoom: 1;
}
.check-style {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
}
.section-agree-all .check-style {
  font-size: 15px;
}
.check-style input {
  margin: 0;
}
.section-mem-agree .check-style {
  margin-top: 20px;
}
input {
  padding: 0;
}
.check-style input {
  margin: 0;
}
.check-style input,
.radio-style input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.button.b-xlarge {
  padding: 0;
  height: 80px;
  font-size: 20px;
  line-height: 80px;
}
.grid-container::after {
  clear: both;
}
.grid-container::before,
.grid-container::after {
  content: '';
  display: table;
}
p {
  word-break: keep-all;
}
.header {
  position: relative;
  width: 100%;
  > img {
    width: 100%;
  }

  .overlay {
    color: White;
    position: absolute;
    text-align: center;
    z-index: 12;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: lighter;
    letter-spacing: -0.1rem;
  }
}

.contentDesc {
  text-align: center;

  .title1 {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .title2 {
    margin-top: 3rem;
  }

  .title3 {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .headerimage {
    width: 100%;
    margin: 0;
    content: url('~@/assets/images/common/Eventinquiry_headerimage.jpg');
  }
}
@media (max-width: 992px) {
  .headerimage {
  }
  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    > img {
      width: 100%;
    }

    .overlay {
      padding-top: 5rem;
      color: White;
      position: absolute;
      text-align: center;
      z-index: 12;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: lighter;
      letter-spacing: -0.1rem;
    }
  }
}
</style>
